import * as React from 'react'
import BaseLayoutTool from '../../components/base-layout-tool'

const MergeSpreadsheetPage = () => {
    return (
        <BaseLayoutTool 
        pageTitle="Merge Spreadsheets | App " 
        pageDescription="Merge spreadsheets based on a common column using this TNNL tool. Works with csv and Excel files."
        toolLink="https://dfmerge-ne2vtntboq-nw.a.run.app"
        />
    )
}

export default MergeSpreadsheetPage